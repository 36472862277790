import React from 'react';
import MobileMenu from './Mobilemenu';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const NMDSINavbar = () => {
  return (
    <>
      <div className="block md:hidden">
        <MobileMenu />
      </div>
      <div className="hidden w-full bg-blue md:block">
        <div className="py-1 mx-auto 5xl:px-5 max-w-7xl">
          <div className="flex items-center justify-between px-12">
            <div>
              <Link to="/">
                <StaticImage
                  src="../../assets/images/NMDSI_logo.svg"
                  alt="Northwestern Mutual Data Science Institute Logo"
                  className="w-2/3 antialiased"
                />
              </Link>
            </div>
            <div>
              <Link className="px-4 text-lg text-white cursor-pointer" to="/blog" data-test-id="Blog">
                Blog
              </Link>
              <Link data-test-id="New &amp; Events" className="px-4 text-lg text-white cursor-pointer" to="/#events">
                Events
              </Link>
              <Link data-test-id="About" to="/#about" className="px-4 text-lg text-white cursor-pointer">
                About
              </Link>
              <Link data-test-id="Contact" to="/#contact" className="px-4 text-lg text-white cursor-pointer">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NMDSINavbar;
