import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = () => {
  return (
    <div className="w-full py-16 bg-white">
      <div className="max-w-6xl px-5 mx-auto">
        <div className="flex flex-col justify-center pb-10 md:flex-row md:justify-between">
          <div className="flex flex-col">
            <a
              href="https://careers.northwesternmutual.com/teams/technology/"
              target="_blank"
              rel="noreferrer"
              className="pb-2 text-base transition-all cursor-pointer text-brown hover:text-blue"
            >
              Tech Careers
            </a>
            <a
              href="https://www.northwesternmutual.com/"
              target="_blank"
              rel="noreferrer"
              className="pb-2 text-base transition-all cursor-pointer text-brown hover:text-blue"
            >
              NM.com
            </a>
            <a
              href="https://www.northwesternmutual.com/privacy-notices/"
              target="_blank"
              rel="noreferrer"
              className="pb-2 text-base transition-all cursor-pointer text-brown hover:text-blue"
            >
              Privacy Notice
            </a>
            <a
              href="https://www.northwesternmutual.com/legal-information/"
              target="_blank"
              rel="noreferrer"
              className="pb-2 text-base transition-all cursor-pointer text-brown hover:text-blue"
            >
              Legal Notice
            </a>
            <button
              type="button"
              role="menuitem"
              id="ot-sdk-btn"
              className="m-0 pb-2 p-0 text-left text-base transition-all cursor-pointer text-brown hover:text-blue ot-sdk-show-settings nmx-override"
            >
              Do Not Sell or Share My Personal Information
            </button>
          </div>
          <div>
            <p className="pb-2 text-base text-brown">720 East Wisconsin Avenue</p>
            <p className="pb-2 text-base text-brown">Milwaukee, WI 53202</p>
            <p className="pb-2 text-base text-brown">(414) 271-1444</p>
          </div>
          <div>
            <div className="flex">
              <a target="_blank" rel="noreferrer" href="https://twitter.com/NM_Financial">
                <StaticImage
                  src="../../assets/images/NM_twitter.png"
                  className="flex mx-1 cursor-pointer "
                  alt="Twitter"
                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/northwesternmutual">
                <StaticImage
                  src="../../assets/images/NM_facebook.png"
                  className="flex mx-1 cursor-pointer "
                  alt="Facebook"
                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/northwestern-mutual/">
                <StaticImage
                  src="../../assets/images/NM_linkedin.png"
                  className="flex mx-1 cursor-pointer "
                  alt="Linkedin"
                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/northwesternmutual/">
                <StaticImage
                  src="../../assets/images/NM_Instagram.png"
                  className="flex mx-1 cursor-pointer "
                  alt="Instagram"
                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCpqT-GdO7vdxxrSbKrfkmDA">
                <StaticImage
                  src="../../assets/images/NM_youtube.png"
                  className="flex mx-1 cursor-pointer "
                  alt="Youtube"
                />
              </a>
            </div>
          </div>
        </div>
        <p className="text-xs capitalize text-brown">
          Copyright © 2021 The Northwestern Mutual Life Insurance Company, Milwaukee, WI. All Rights Reserved.
          Northwestern Mutual is the marketing name for The Northwestern Mutual Life Insurance Company and its
          subsidiaries.
        </p>
      </div>
    </div>
  );
};

export default Footer;
